@font-face {
  src: local("NewTimes"), url("./components/fonts/Chomsky.woff") format("woff");
  font-family: "NewTimes";

  }
.App{
//  height: 100vh;

  @media (min-width: 1800px){
  width: 100%;
  }
  // height: 100vh;
  /* max-width: 1600px; */
  /* position: relative; */
  background-color: rgba(253, 253, 253, 0.993);
  &-account{
    
    position: relative;
    background-color: #050505;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: linear-gradient(120deg, rgba(18,18,22,1) 50%, rgb(83, 13, 13) 99%);
  
    &--left{
      width: 50%;
      @media (max-width: 850px){
        display: none;
      }
    }
    &--right{
      width: 50%;
      @media (max-width: 850px){
        width: 100%;
      }
      // overflow: hidden;
      position: relative;
      &-error{
        position: absolute;
        padding: 40px 0px;
        box-shadow: 0px 0px 5px 5px rgba(36, 35, 35, 0.534);
        background-color: black;
        color: rgb(211, 211, 211);
        width: 100%;
        text-align: center;
      }
      height: 100vh;
      box-shadow: 3px 3px 5px 10px rgba(5, 5, 5, 0.308);
      background-image: linear-gradient(to right bottom, #c2c2c2,#f3f2f2);
      // background-color: #f3f2f2;
      
    }
  
  }
}

.main{
  // height: 100;
  // z-index: 9999;
  @media (min-width: 1800px){
    position: absolute;
    max-width: 1800px;
    /* top: 50%; */
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  
  
  /* margin-left: 10vw; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  
  /* display: inline-block; */
  
}
// white background #ece9e8

