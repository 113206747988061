.wrapper{
  display: flex;
  justify-content: center;
  &-modal{
    width: 100vw;
    position: absolute;
    z-index: 9999;
    position: fixed;
  }
  &-category{
    position: absolute;
    width: 100vw;
  }
}

.home-class{
  width: 100vw;
  max-width: 1600px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-self: center;
  min-height: 100vh;
  &-filters{
    display: flex;
    padding: 5px;
    justify-content: flex-end;
    &-text--selected{
      cursor: pointer;
      background-color: rgb(29, 41, 44);
      padding: 10px 25px;
      border-radius: 20px;
      border: 1px solid black;
      color: white;
    }
    &-text{
      cursor: pointer;
      border: 1px solid black;
      padding: 10px 25px;
      border-radius: 20px;
    }
  }
}
