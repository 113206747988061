.model-responsive{
  width: 100%;
}
.header {
  position: relative;
  
  &-logo{
    display: flex;
    
    align-items: center;
   
   
  }
  &-menu{
    padding: 0 5%;
    @media (max-width: "500px") {
      padding: 0 2%;
    }
    &-responsive{
      display: none;
      cursor: pointer;
      @media(max-width: 970px){
        display: block;
        padding-top: 2rem;
        div{
        width: 35px;
        height: 3px;
        background-color: black;
        margin: 6px 0;
        }
        
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-date{
      width: 5%;
      h6{
        padding-top: 5px;
        text-align: center;
      }
      @media(max-width: 970px) {
        width: 10%;
        
      }
      font-size: 1.1rem;
      font-weight: 600;
      span{
        
        padding-top: 0.5rem;
        
        position: absolute;
      }
      @media(max-width: 970px){
      
        &-date{
          display: none;
        }
        
      }
      
      
    }

    &-links{
      padding: 0 2%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 5px;
      padding-bottom: 5px;
      border-top: 2px solid black;
      border-bottom: 2px solid black;
      border-bottom-style: double;
      border-bottom-width:5px;
      &-list{
       
        font-size: 1rem;
        display: flex;
        list-style: none;
        justify-content: space-evenly;
       
        align-content: center;
        align-self: center;
        transition: all 0.5s ease-in;
        &-active{
          // color: rgb(172, 21, 10);
          text-decoration: line-through;
          text-decoration-color: red;
          
        }
        li{
          cursor: pointer;
          margin-right: 2rem;
          &:hover{
            // color: rgb(122, 14, 7);
            text-decoration: line-through;
            text-decoration-color: red;
          }
          &::first-letter{
            text-transform: capitalize;
          }
        }
        @media(max-width: 970px){
          display: none;
        }
      }
      &-date{
        display: flex;
        justify-content: space-between;
        width: 100%;
       
        @media(min-width: 970px) {
          display: none;
        }
      }
      transition: all 0.5s ease-out;
      margin: 0 5%;
      @media (max-width: "500px") {
        margin: 0 2%;
      }
      &-scrolled{
      box-shadow: 0 15px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      margin: 0;
      transition: all 0.5s ease-out;
      position: fixed;
      top: 0;
      width: 102%;
      background-color: white;
      z-index: 99;
      }
    }




    &-account{
      min-width: 5%;
     &-content{
       font-size: 0.8rem;
      padding-top: 2rem;
      //  display: none;
      transition: all 0.5s ease-in;
      &-buttons{
        
        padding: 10px;
        border: 2px solid black;
        border-radius: 30px;
        cursor: pointer;
        &:hover{
          color: rgb(0, 0, 0) !important;
          border: 2px solid rgb(122, 14, 7);
        }
      }
      span{
        transition: all 0.5s ease-in;
        cursor: pointer;
        // color: rgb(77, 77, 77);
        // font-size: 1.1rem;
        
        &:hover{
          color: gray;
        }
      }

      &-username{
        // position: fixed;
        position: absolute; 
        width: auto;
        text-align: right;
        right: 5%;
        
        &-arrow{
          margin-left: 3px;
          margin-bottom: 3px;
          border: solid black;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
        &-actions{
          min-width: 200px;
          &-menu{
            // width: inherit;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &-contact{
              border-right: 1px solid black;
              width: 60%;
              span{
                font-size: 0.7rem;
              }
              h4{
                &:hover{
                  color: rgb(0, 114, 167);
                }
              }
              // width: 50px;
              text-align: center;
            }
            ul{
            
              list-style: none;
            li{
              &:hover{
                color: rgb(0, 114, 167);
                z-index: 9999;
              }
              cursor: pointer;
              padding: 8px;
              z-index: 5;
            }
          }
          }
          font-size: 0.9rem;
          color: black;
          list-style: none;
          padding: 10px;
          border-radius: 10px;
          
          z-index: 99999;
          background-color: rgb(228, 228, 228);
        }
        &-actions{
          display: none;
        }

        &:hover &-actions{
          display: block;
        }
        // &-actions{
        //     display: block;
        //   }

        &:hover &-arrow{
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
          margin-bottom: 0px;
        }
      }
     }
      img{
        width: 2.5rem;
        cursor: pointer;
        
      }
      
      
    }
  }
  width: 100%;
  overflow: hidden;
  &-headline-width{
    padding: 0 5%;
    @media (max-width: "500px") {
      padding: 0 2%;
    }
  }
  &-headline {
    margin-top: 20px;
    height: 30px;
    overflow: hidden !important;
    &-brodcaster{
      padding: 10px;
      background-color: black;
      color: white;
    }
    h4{
      overflow: hidden;
      font-size: 1rem;
      color: inherit;
      width: max-content;
      line-height: 30px;
      text-align: center;
      animation: heading 1000s linear infinite;
     
      &:hover{
        animation-play-state: paused;
      }
      a {
        overflow: hidden;
        text-decoration: none;
        color: inherit;
        border-left: 1px solid black;
        margin: 0px 5px;
        padding-left: 10px;
        &:hover{
          animation-play-state: paused;
          color: rgb(122, 14, 7)
        }
      }
    }
    overflow: hidden;
    
  }
  @keyframes heading {
    0% {
      transform: translateX(1%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  // &-name{
  //   // h4{
  //   //   font-weight: 500;
  //   //   &:not(:first-child){
  //   //     // padding-left: 20px;
  //   //   }
  //   // }
  //   // // display: flex;
  //   // padding-right: 10px;
  //   // margin-right: auto;
    
  // }
  &-mini {
    
    padding: 10px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(0, 0, 0);
    color: white;
    
    
    span {
      cursor: pointer;
      font-size: 1rem;
      color: white;
      // margin-right: 20px;
      &:not(:last-child) {
        // padding-right: 5px;
        border-right: 1px solid white;
      }
      background-color: transparent;
      border: none;
      outline: none;
    }
  }
  &-text {
    // width: 10%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 10px;
    // padding-left: 5%;s

    font-size: 4rem;
    @media(max-width: 700px) {
      font-size: 3rem;
      
    }
    @media(max-width: 400px) {
      font-size: 2.5rem;
      
    }
    font-family: NewTimes;
    span{
      // flex-direction: ;
      font-family: NewTimes;
    }
   
  }
  &-update-date{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

  }
}
