.modelCategory{
    width: 100vw;
    padding: 15px;
    padding-top: 20px;
    min-height: 700px;
    height: 100vh;
    position: fixed;
    overflow-y: hidden;
    z-index: 1000;
    background-color: rgb(231, 231, 231);
    &-head{
        padding-bottom: 10px;
        border-bottom: 2px solid black;
        &-text{
            font-family: newTimes;
            font-size: 3rem;
        }
        display: flex;
        justify-content: space-between;
        &-cross{
            cursor: pointer;
            &-bar1,&-bar2,&-bar3 {
                width: 25px;
                height: 5px;
                background-color: rgb(0, 0, 0);
                margin: 6px 0;
                z-index: 990;
              }
            &-bar1 {
                -webkit-transform: rotate(-45deg) translate(-9px, 6px);
                transform: rotate(-45deg) translate(-9px, 6px);
              }
              &-bar2 {opacity: 0;}
              &-bar3 {
                -webkit-transform: rotate(45deg) translate(-8px, -8px);
                transform: rotate(45deg) translate(-8px, -8px);
              }
        }
        &-empty{
            width: 6%;
        }
    }
    &-list{
        // height: 85vh;
        // overflow: scroll;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        li{
            font-size: 1.5rem;
            padding: 10px;
            cursor: pointer;
            &:hover{
                text-decoration: line-through;
                text-decoration-color: red;
            }
           
        }
        .active{
            text-decoration: line-through;
            text-decoration-color: red;
        }
    }
}