.footer{
    width: 101%;
    margin-top: 20px;
    color: #ccc;
    padding: 0px 5%;
    border-top:  2px solid black;
    padding-top: 40px;
    background-color: #000000;
    background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media(max-width: 800px) {
        justify-content: space-around;
    }
    
    h4{
        padding-bottom: 20px;
    }
    &-main{
        
        
        h3{
            font-size: 3rem;
            font-family: newTimes;
        }
        width: 30%;
        p{
            padding-top: 5px;
            // width: 40%;
            font-size: 0.8rem;
        }
        @media(max-width: 800px) {
            flex: 0 0 100%;
            text-align: center;
            padding-bottom: 20px;
        }
    }
    &-category{
        display: flex;
        flex-direction: column;
        // width: 25%;
        ul{
            list-style: none;
            display: flex;
            flex-direction: column;
           li{
               margin-bottom: 10px;
               cursor: pointer;
               &:hover{
                color: rgb(8, 119, 184);
                text-decoration: underline;
               }

           }
            
        }
    }
    &-follow{
        ul{
            list-style: none;
        }
        
        li{
            padding-bottom: 15px;
            a{  
                color: inherit;
                text-decoration: none;
                &:hover{
                    color: rgb(8, 119, 184);
                    text-decoration: underline;
                  }
            }
        }
    }
    &-contact{
        @media(max-width: 800px) {
            flex: 0 0 100%;
            text-align: center;
            padding-bottom: 20px;
            padding-top: 10px;
        }
        ul{
            
                display: flex;
                
                justify-content: space-between;
                // width: 50%;
            
            list-style: none;
            li{
                @media(max-width: 800px){
                    &:not(:first-child){
                        padding-left: 15px;
                    }
                 
                }
                padding-bottom: 10px;
                img{
                    width: 30px;
                }
            }
        }
    }
}