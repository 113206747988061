.brodcaster{
  display: block;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 20px;
  span{
    font-size: 1.2rem;
  }
  @media(max-width: 700px){
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
    Span{
      padding-bottom: 10px;
      font-weight: 600;
    }
  }
}

.content{

  &-NewYorkTimes,&-BBC,&-Gurdian,&-CNN{
    padding: 0 5%;
    @media (max-width: "500px") {
      padding: 0 2%;
    }
    // margin-bottom: 5%;

  }
  // &-NewYorkTimes{
  //   margin-top: 4%;
  //   background-color: #1b2845;
  //   background-image: linear-gradient(315deg, #1b2845 0%, #2c486d 74%);
  //   padding-bottom: 5%;
  //   color: #ccc;
  // }

  // &-BBC{
  //   background: rgb(83,12,14);
  //   background: linear-gradient(333deg, rgba(83,12,14,1) 11%, rgba(219,13,49,1) 100%);
  //   padding-bottom: 5%;
  //   color: #ccc;
  // }

  // &-Gurdian{
  //   background: #1e3c72; 
  //   background: -webkit-linear-gradient(to right, #2a5298, #1e3c72); 
  //   background: linear-gradient(to right, #2a5298, #1e3c72);
  //   padding-bottom: 5%;
  //   color: #ccc;

  // }
  // &-CNN{
  //   background-color: #485461;
  //   background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
  //   padding-bottom: 5%;
  //   color: #ccc;
  // }

  &-view{
    &-card{
     
      &-text{
        &-more{
          border-top: 1px solid rgb(23, 133, 207);
          padding-top: 15px;
          color: rgb(68, 150, 197);
        }
        &-less{
          color: rgb(68, 150, 197);
          border-bottom: 1px solid rgb(23, 133, 207);
          padding-bottom: 15px;
        }
        
        margin: 10px 0px;
        // position: absolute;
        width: 97%;
        text-align: right;
        margin: 50px 0px;

        color:#ccc;
        cursor: pointer;
      }
      &-news{
        padding-top: 10px;
        display: grid;
        grid-template-columns: auto auto auto auto;
        column-gap: 2%;
        @media(max-width: 700px) {
          grid-template-columns: auto auto;
        }
        a{
          text-decoration: none;
          color: inherit;
          font-size: 1.1rem;
          &:hover{
            color: rgb(8, 119, 184);
            text-decoration: underline;
          }
          img{
            // padding: 10px 0px;
            margin-top: 10px;
            max-height: 227px;
          }
        }
        
      }
    }
    
  }
}
.Card{
  display: grid;

  grid-template-columns: 55% 21% 21%;
 
  column-gap: 1.5%;
 @media(max-width: 1070px){
  grid-template-columns: 65% 30%;

  row-gap: 1%
 }
 @media(max-width: 970px){
  grid-template-columns: 98%;

  row-gap: 1%;

 }
 @media(max-width: 700px){
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto;
  row-gap: 0%;

 }
 &-ul{

  list-style: none;
  li{
    &:not(:first-child){
      padding-top: 10px;
    }
    a{
      display: flex;
      span{
        padding-left: 10px;
        font-weight: 600;
        &:hover{
          color: rgb(8, 119, 184);
          text-decoration: underline;
        }
      }
    }
    font-size: 0.9rem;
    img{
      width: 90px;
    }
  }

}
    a{
    text-decoration: none;
    color: inherit;
    h4,h2{
       &:hover{
        
      color: rgb(8, 119, 184);
      text-decoration: underline;
    }
    }
   
  }



  &-paragraph{
    &-cnn{
      a{
        h4{
          
          margin-bottom: 5px;
        }
      }
    }

    img{
      width: 100%;
      max-height: 139px;
      object-fit: cover;

    }
    &-text{
      font-size: 1.1rem;

      }
     
    
     p{
        padding-top: 5px;
        padding-bottom: 15px;
        font-size: 1.1rem;
      }

    
      @media(max-width: 700px) {
        img{
          transition: all 0.5s;
          width: 100%;
          // max-height: 192px;
          filter:  brightness(80%);
          &:hover{
            filter:  brightness(100%);
          }
          
    
    
        }
        a{
          position: relative;
          
        }
        &-text{
         
          position: absolute;
          bottom: 0px;
          color: rgb(245, 243, 243);
          font-size: 1.1rem;
          padding:  20px 5px; 
          width: 100%;
          background: linear-gradient(
            to bottom,
            rgba(255, 0, 0, 0) 0%,
            rgba(22, 21, 21, 0.815) 100%
          );
          @media (max-width: 400px) {
            position: relative;
            bottom: 0px;
            color: black;
            font-size: 1.1rem;
            padding:  0px 0px; 
            width: 100%;
            background: transparent;
          }
          &:hover{
            background: linear-gradient(
              to bottom,
              rgba(255, 0, 0, 0) 0%,
              rgb(22, 21, 21) 100%
            );
          }
         
        }
         p{
            padding-top: 5px;
            padding-bottom: 15px;
            font-size: 1.1rem;
            // @media(max-width: 700px) {
            //   font-size: 1.2rem;
            // }
          }
        }
  }



  &-main{
   
    width: inherit;
    &-1{
      // @media(min-width: 1200px) {
      //   border-right: 2px solid black;
      // }
      
      // padding-right: 10px;
      &-cnn{
        a{
          h4{
            
            margin-bottom: 5px;
          }
        }
      }
      display: grid;
      row-gap: 2%;
      @media(max-width: 970px){
        row-gap: 1%;
      }
      @media(max-width: 1070px){
        row-gap: 1%;
      }
      @media(max-width: 700px){
        row-gap: 1%;
      }
      &-hero{
        position: relative;
        // @media(min-width: 1200px) {
        // border-bottom: 2px solid black;
        // padding-bottom: 10px;
        // }
       
        &-container{
          display: flex;
          justify-content: space-between;
          
          @media(max-width: 700px){
            flex-direction: column-reverse;
            font-size: 1.3rem;
          
          &-text{
        
            &-anchor{
              position: absolute;
              top: 0px;
              padding: 5px 10px;
              padding-bottom: 30%;
              color: rgb(243, 243, 243);
              background: linear-gradient(
                to top,
                rgba(255, 0, 0, 0) 0%,
                rgba(22, 21, 21, 0.815) 100%
              );
              &:hover{
                background: linear-gradient(
                  to top,
                  rgba(255, 0, 0, 0) 0%,
                  rgb(22, 21, 21) 100%
                );
              }
            // padding-bottom: 10px;
          }
          }
          
        
        }
        &-text{
          &-anchor{
            padding-bottom: 10px;
          }
          
        }
        img{
          height: 250px;
          padding-left: 5px;
          @media(max-width: 700px){
            padding-top: 10px;
            width: 100%;
            height: auto;
            padding-left: 0px;
          }
          
        }
      }
      }




      &-content{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        column-gap: 1%;
        @media(max-width: 700px){
          grid-template-columns: repeat(2,1fr);
        }
      }
    }



    
    &-2, &-3{
      width: inherit;
      display: grid;
      @media(max-width: 700px) {
        &-peragraph{
          a{
            h4{
              font-size: 1.5rem;
            }
           
          }
          p{
            font-size: 1.2rem;
          }
        }
      }
     
      @media(max-width: 970px) {

        width: 100%;
        img{
          width: 99%;
        }
        grid-template-columns: 20% 80%;
        @media(max-width: 700px){
          grid-template-columns: 99%;

        }
        
        ul{
  
          display: grid;
          grid-template-columns: repeat(4,1fr);
          @media(max-width: 700px){
            grid-template-columns: repeat(2,1fr);

          }
          
          li{
            &:not(:first-child){
              padding-top: 0px;
            }
            a{
              display: flex;
              flex-direction: column;
              padding: 0px 5px;
              
              span{
                padding-left: 0px;
                font-weight: 600;
                padding-top: 10px;
                padding-bottom: 10px;
                &:hover{
                  color: rgb(8, 119, 184);
                  text-decoration: underline;
                }
              }
            }
            font-size: 1.1rem;
            
              img{
                width: 100%;
                
              }
            
          }
        }
        p{
          display: none;
          @media(max-width: 700px) {
            display: block;
          }
        }
       
       }
    }


    // &-2{
    //   @media(min-width: 1200px) {
    //     border-right: 2px solid black;
    //     padding-right: 10px;
    //     margin-left: 10px;
    //   }
    // }
    &-3{
    
      width: inherit;
      display: grid;
      // @media(min-width: 1200px) {
      //  padding-left: 10px;
      // }
      @media(max-width: 700px) {
      &-peragraph{
        a{
          h4{
            font-size: 1.5rem;
          }
         
        }
        p{
          font-size: 1.2rem;
        }
      }
    }
      @media(max-width: 1070px){
        img{
          width: 100%;
        }
        grid-template-columns: 20% 80%;
        @media(max-width: 700px){
          grid-template-columns: 100%;
        }
       
        ul{
          
          display: grid;
          grid-template-columns: repeat(4,1fr);
          @media(max-width: 700px){
            grid-template-columns: repeat(2,1fr);
          }
          
          li{
            &:not(:first-child){
              padding-top: 0px;
            }
            a{
              display: flex;
              flex-direction: column;
              padding: 0px 5px;
              span{
                padding-left: 0px;
                font-weight: 600;
                padding-top: 10px;
                padding-bottom: 10px;
                &:hover{
                  color: rgb(8, 119, 184);
                  text-decoration: underline;
                }
              }
             

            }
            font-size: 1.1rem;
            
              img{
                width: 100%;
                
              }
            
          }
        }
        p{
          display: none;
          @media(max-width: 700px) {
            display: block;
          }
        }
       
       }
      
    }
  }

 

  
}











