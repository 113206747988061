.container{
   width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(247, 247, 247, 0.582);
    position: relative;
    z-index: 800;
}

@keyframes popup {
    0%   {transform: scale(0.5);}
    100% {transform: scale(1);}
  }
.modal{
    z-index: 9999;
    animation: popup 0.5s alternate ease-in;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    &-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 700px;
       
        height: 600px;
        
        
        background-color: black;
        z-index: 900;
        border-radius: 15px;
        color: rgb(221, 221, 221);
        @media (max-width: 700px){
            width: 100vw;
            height: 100vh;
            border-radius: 0px;
           
            }
        
    }
}

.modal-container-logo{
    width: inherit;
    padding-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    // position: absolute;
    &-border{
    height: 15px;
    border: none;
    width: inherit;
    border-bottom: 4px solid red;
    position: absolute;
    // z-index: 99999;
    } 
    &-img{
        z-index: 4;
        position: absolute;
        width: 60px;
        
    }
    &-close{
        width: 100%;
        height: 100%;
        display: flex;
        align-self: flex-end;
        align-content: center;
        justify-content: flex-end;
        align-items: flex-end;
        // margin: 9px;
        // display: flex;
        // width: 25px;
        // position: absolute;
        // top: 75px;
        // right: 425px;
        &-img{
            width: 30px;
            position: absolute;
            margin-bottom: 20px;
            margin-right: 10px;
            cursor: pointer;
        }
    }
}

.form{
    height: 100%;
    width: 100%;
    &-signin{
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        &-email{
            display: flex;
            flex-direction: column;
        }
        &-password{
            display: flex;
            flex-direction: column;
        }
        &-action{
            display: flex;
            flex-direction: column;
            &-button{
                
                button{
                 
                    cursor: pointer;
                    font-size: 1.2rem;
                    display: flex;
                    font-weight: 700;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 10px;
                    width: 320px;
                    background-color: rgb(252, 252, 252);
                    img{
                        padding-right: 5px;
                        width: 35px;
                    }
                }

                span{
                    display: flex;
                    font-weight: 700;
                    justify-content: center;
                    align-items: center;
                }
            }
            &-forgot{
                cursor: pointer;
                font-size: 0.8rem;
                margin-bottom: 30px;
            }
        }

        &-signup-text{
            color: rgb(243, 241, 241);
            &-button{
                cursor: pointer;
                color: rgb(235, 230, 230);
                font-weight: 700;
                &:hover{
                    color: rgb(255, 255, 255);
                    text-decoration: underline;
                }
            }   
        }
        &-error{
            text-align: center;
            font-size: 0.9rem;
            color: rgb(223, 14, 14);
          }
    }
    input[type=email],
input[type=password]{
    margin-top: 10px;
  transition: all 0.5s;
//    font-size: 1.2rem;
color: rgb(216, 216, 216);
   border: none;
   border: 1px solid #a3a1a1;
   width: 300px;
  
   height: 45px;
   padding-left: 15px;
   background-color: #e0e0e000;
   
  }
}

