.login{
  position: relative;
  height: 550px;
  width: 450px;
  // padding: 1.5rem;
 
  
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(128, 128, 128, 0.151);
  border-radius: 10px;
  color: white;
  @media (max-width: 700px){
    width: 100vw;
    height: 100vh;
    }
  
  // h1{
  //   padding-bottom: 20px;
  // }
  
 &--input{
   padding: 10px 0px;

   &--buttons{
     width: 34.5vw;
     display: flex;
     flex-direction: column;
     justify-content: center;
     text-align: center; 
     &--text{
       padding: 10px 0px;
     }
   }

  
 }

 .form{
  height: 100%;
  width: 100%;
  // padding: 2.5rem;

  &-signin{
    &-hero{
      font-family: NewTimes;
      font-size: 3.5rem;
      &-error{
        text-align: center;
        font-size: 0.9rem;
        color: rgb(223, 14, 14);
      }
    }
      height: inherit;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      &-email{
          display: flex;
          flex-direction: column;
      }
      &-password{
          display: flex;
          flex-direction: column;
      }
      &-action{
          display: flex;
          flex-direction: column;
          &-button{
              button{
                cursor: pointer;
                  font-size: 1.2rem;
                  display: flex;
                  font-weight: 700;
                  justify-content: center;
                  align-items: center;
                  padding: 10px 10px;
                  width: 320px;
                  background-color: rgb(252, 252, 252);
                  img{
                      padding-right: 5px;
                      width: 35px;
                  }
              }
              span{
                display: flex;
                  font-weight: 700;
                  justify-content: center;
                  align-items: center;
              }
          }
          &-forgot{
              cursor: pointer;
              font-size: 0.8rem;
              margin-bottom: 30px;
          }
      }

      &-signup-text{
          color: rgb(243, 241, 241);
          &-button{
              cursor: pointer;
              color: rgb(235, 230, 230);
              font-weight: 700;
              &:hover{
                  color: rgb(255, 255, 255);
                  text-decoration: underline;
              }
          }   
      }
  }
  input[type=email],
input[type=password]{
  margin-top: 10px;
transition: all 0.5s;
//    font-size: 1.2rem;
color: rgb(216, 216, 216);
 border: none;
 border: 1px solid #a3a1a1;
 width: 300px;

 height: 45px;
 padding-left: 15px;
 background-color: #e0e0e000;
 
}
}

 

//  button{
//   transition: all 0.2s;
//   background-color: #e0e0e08a;
//   outline: 0;
//    cursor: pointer;
//    width: 100%;
//    height: 40px;
//   //  padding: 10px 25px;
//   // padding: 0px 55px;
//    font-size: 1.2rem;
//    border-radius: 30px;
//    border: 1px solid black;
//    &:hover{
//     background-color: #e6e6e6;
//    }
//  }

//  input[type=email]{
//   background-image: url("../img/email.png");
//   background-position: 10px 4px; 
//   background-repeat: no-repeat;
//   background-size: 40px;
// }

// input[type=password]{
//   background-image: url("../img/lock.png");
//   background-position: 13px 5px; 
//   background-repeat: no-repeat;
//   background-size: 32px;
// }

// input[type=email],
// input[type=password]{
//   transition: all 0.5s;
//   -webkit-border-radius: 20px;
//   -moz-border-radius: 20px;
//    border-radius: 20px;
//    font-size: 1.2rem;
//    border: none;
//    border: 1px solid #a3a1a1;
//    width: 30vw;
//    @media (max-width: 850px){
//     width: 70vw;
//   }
//    height: 45px;
//    padding-left: 55px;
//    background-color: #e0e0e08a;
   
//   }
  
//   input[type=email]:focus,
// input[type=password]:focus {
//    outline: none;
//    background-color: #e6e6e6;
// }
// }
}
